.image {
  &.shape-round {
    img {
      border-radius: 50%;
    }
    &:after {
      border-radius: 50%
    }
  }

  &.has-shape {
    max-width: 300px;
    width: 90%;
    position: relative; // .has-shape on picture element.
    // Add a bit of offset

    img {
      position: relative;
      z-index: 1; // above :after.
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0; // below img.
    }

    $offset: 10%;
    &.bottom-right {
      margin-bottom: $offset * 1.5;
      margin-top: $offset;
      right: $offset / 2;
      &:after {
        top: $offset;
        left: $offset;
      }
    }
    &.bottom-left {
      margin-bottom: $offset * 1.5;
      margin-top: $offset;
      left: $offset / 2;
      &:after {
        top: $offset;
        right: $offset;
      }
    }
    &.top-right {
      margin-top: $offset * 1.5;
      margin-bottom: $offset;
      right: $offset / 2;
      &:after {
        bottom: $offset;
        left: $offset;
      }
    }
    &.top-left {
      margin-top: $offset * 1.5;
      margin-bottom: $offset;
      left: $offset / 2;
      &:after {
        bottom: $offset;
        right: $offset;
      }
    }
  }

  // pattern-color class is applied to picture element. so we bubble down to the pseudo after.
  &.wedgewood {
    &:after {
      @include stripes(-28deg, #4E7C96, #CFE0E9);
    }
  }


  // Rode beeldvlakken: #BD1F2B (feller rood dan nu) met roze strepen: ##DB7E69
  &.raw-sienna {
    &:after {
      @include stripes(-28deg, #BD1F2B, #DB7E69);
    }
  }
  // Old #CF6047, #DB7E69

  &.link-water {
    &:after {
      @include stripes(-28deg, #E5F0F7, #F0F6FA);
    }
  }

  // Light yellow and dark yellow lines
  &.buttermilk {
    &:after {
      @include stripes(-28deg, #FFF8B6, #FFF246);
    }
  }

  // Light green and darker green lines.
  &.frost {
    &:after {
      @include stripes(-28deg, #EAF7E1, #CAEBB4);
    }
  }
}