.link-blocks {
  display: flex;
  flex: 1 0 auto;

  // If the link blocks has more than 1 item in it we aplly different flex rulezz.
  &.multiple {
    flex-wrap: wrap;

    .card {
      max-width: 100%;
      margin-bottom: 2rem;

      @include media-breakpoint-up($bpDown) {
        max-width: 50%;
        margin-bottom: 0;
      }
      @include media-breakpoint-up($bpUp) {
        max-width: 25%;
      }

    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: currentColor;
  margin-bottom: 2rem;
}

// Card color themes!
// Geel
.candlelight {
  .card-title {
    background-color: #FFD912;
    color: #333333;
  }
  .card-content {
    background-color: #FFFCD7;
  }
}
// Licht geel
.turbo {
  .card-title {
    background-color: #FFEE04;
    color: #333333;
  }
  .card-content {
    background-color: #FFF8D4;
  }
}

// Donker geel
.scotch-mist {
  .card-title {
    background-color: #FFC804;
    color: #333333;
  }
  .card-content {
    background-color: #FFF790;
  }
}

// Donker blauw
.deep-sea-green {
  .card-title {
    background-color: #0C496E;
    color: #C0E0F0;
  }
  .card-content {
    background-color: #C0E0F0;
  }
}


// Blauw
.wedgewood {
  .card-title {
    background-color: #4E7C96;
    color: #E1E9ED;
  }
  .card-content {
    background-color: #E5F0F7;
  }
}

// Rood
.cardinal {
  .card-title {
    background-color: #BD1F2B;
    color: #FFE7DE;
  }
  .card-content {
    background-color: #FFE7DE;
  }
}

// Licht rood
.raw-sienna {
  .card-title {
    background-color: #CF6047;
    color: #FFE7DE;
  }
  .card-content {
    background-color: #FFE7DE;
  }
}
// End card themes


.card-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 2rem;
  margin: 0;
  line-height: 1; // Reset base typography.
  // Create a min height to account for 2 lines height.
  // Only for scholen page...
  // min-height: 65px;

  // If card has icon, set height.
  svg {
    height: 2rem;
    width: 2rem;
    fill: currentColor;
    flex: none; // Makes sure the icons does not get squished.
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
.card-title,
.card-text {
  padding: 1rem;
  letter-spacing: 0.05rem;
}

.card-text {
  line-height: 1.4;
}

// Add flex to all parents so that block elementts grow to the MAX!
.plate--element__card_element {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

// special ovverride for scholen page /onze-scholen.
#section-scholen_blokken {
  .link-blocks {
    @include media-breakpoint-down($bpDown) {
      margin-bottom: 4rem;
    }
  }

  .card-title {
    min-height: 6rem;
  }
}

[id="section-onze-scholen-op-de-kaart"] {
  .map {
    @include media-breakpoint-down($bpDown) {
      padding-bottom: 100%;
    }
  }
}

