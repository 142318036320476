// Wrapper element for 1 or more buttons.
.cta-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    margin-bottom: 2rem;
  }
  // Alignment of the grouped buttons within wrapper element.
  &.align-left {
    justify-content: flex-start;
    a {
      margin-right: 2rem;
    }
  }
  &.align-center {
    justify-content: center;
    a {
      margin-right: 2rem;
    }
  }
  &.align-right {
    justify-content: flex-end;
    a {
      margin-left: 2rem;
    }
  }

  // Button coloring.
  // Color naming based on chir.ag. The name is based on the background-color.
  // https://chir.ag/projects/name-that-color/#EAF7E1
  .wedgewood {
    background-color: #4E7C96;
    color: #CFE0E9;
  }
  .botticelli {
    background-color: #CFE0E9;
    color: #4E7C96;
  }
  .tuft-bush {
    background-color: #FFD8C9;
    color: #CF6047;
  }
  .frost {
    background-color: #EAF7E1;
    color: #91C073;
  }

  a {
    display: flex;
    align-items: center;

    svg {
      height: 2rem;
      width: 2rem;
      margin-left: .8rem;
      fill: currentColor;
    }
  }
}