.section {
	&--center {
		.plate--column {
			justify-content: center;
		}
	}

	&--balance {
		@include media-breakpoint-up($bpUp) {
			.plate--row {
				flex-wrap: nowrap;
			}

			.plate--column {
				min-width: unset;
				width: unset;
				max-width: unset;
				flex-grow: 1;
				flex-basis: unset;
				flex-shrink: 1;
			}
		}
	}
}

.no_padding_top {
	padding-top: 0;
}

.no_padding_bottom {
	padding-bottom: 0;
}

// Section column colors combis.
// With background and text color.
// Donkerrood
//.cardinal {
//	background-color: #BD1F2B;
//	color: #F7AF9A;
//}
//
//// Rood
//.raw-sienna {
//	background-color: #CF6047;
//	color: #FEF3EF;
//}
//
//// Lichtrood
//.rose-bud {
//	background-color: #F7AF9A;
//	color: #FEF3EF;
//}
//
//// Roze
//.pippin {
//	background-color: #FFE7DE;
//	color: #4E7C96;
//}
//
//// Blacksqueeze
//.black-squeeze {
//	background-color: #F0F6FA;
//	color: #4E7C96;
//}
//// Buttermilk
//.buttermilk {
//	background-color: #FFF8B6;
//	color: #4E7C96;
//}
//
//// Blauw
//.wedgewood {
//	background-color: #4E7C96;
//	color: #CFE0E9;
//}
//
//// donker geel
//.supernova {
//	background-color: #FFC804;
//	color: #4E7C96;
//}
//// geel
//.mustard {
//	background-color: #FFE352;
//	color: #4E7C96;
//}
//
//// Lichtgeel
//.gorse {
//	background-color: #FFF246;
//	color: #4E7C96;
//}
//
//// Donkergroen
//.olivine {
//	background-color: #91C073;
//	color: #EAF7E1;
//}
//
//// Groen
//.feijoa {
//	background-color: #A5D08A;
//	color: #EAF7E1;
//}
//
//// Lichtgroen
//.caper {
//	background-color: #C0E7A7;
//	color: #91C073;
//}