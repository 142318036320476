// Headings and font.
.contact-section {

  // Reused on sections:
  // - contact_section
  // - contactform_section
  .contact-data {
    margin-top: 4em;

    a {
      font-weight: 700;
      text-decoration: none;
      color: currentColor;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

  }

  .contact-lines {
    margin-top: 5rem;
  }
  .contact-line {
    display: flex;
    line-height: 1.8;
    align-items: center;

    color: #4E7C96;
    font-size: 1.8rem;
    font-weight: 700;

    a {
      color: currentColor;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    svg {
      margin-right: 1rem;
      height: 2rem;
      width: 2rem;
      fill: currentColor;
    }
  }

  h2,
  h3 {
    color: #4E7C96;
    font-weight: 500;
    margin: 0;
    line-height: 1.4;
  }

  p {
    color: #4E7C96;
  }

  h3 {
    font-size: 2.4rem;
    font-weight: 500;
  }
  h4 {
    color: #333;
  }

}

// Map stylez
.map-wrapper {
  position: relative;
  margin-top: 6%;

  // Fancy pancy stripes pattern behind the map.
  &:after {
    content: '';
    position: absolute;
    width: 60%;
    height: 120%;
    z-index: 0;
    top: -12%;
    bottom: -10%;
    right: 3%;

    @include stripes(-28deg, #FFF246, #FFF8B6);
  }
}

[id='map-contact'] {
  width: 90%;
  position: relative;

  // A bit bigger mapo.
  padding-bottom: 50%;

}