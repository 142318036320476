.contactforms {

  .first-stripes {
    height: 100px;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100px;
      left: 0;
      right: 0;
      z-index: 0; // below img.
      @include stripes(-28deg, #4E7C96, #7EABC7);
    }

  }

  background-color: #EDF7FE;
}
