.paragraph {
	ul,
	ol,
	p,
	table {
		margin-bottom: 1.6rem;
	}
	ul {
		li {
			padding-left: 1.5rem;
			position: relative;

			&::before {
				content: "";
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 50%;
				display: block;
				background-color: currentColor;
				position: absolute;
				left: 0;
				top: 1rem;
			}
		}
	}

	ol {
		counter-reset: my-awesome-counter;

		li {
			padding-left: 2rem;
			counter-increment: my-awesome-counter;

			&::before {
				content: counter(my-awesome-counter) ". ";
				top: 0;
				font-weight: 600;
			}
		}
	}


}

// When titles are place inside editable content they should be standard blueish.
// But do not interfere with the color per column function.
.section__inner:not(.has-column-color) .paragraph {
	h2 {
		color: #4E7C96;
	}
	h4 {
		color: #333;
	}

	a {
		font-weight: 700;
		text-decoration: none;
		color: #4E7C96;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

}
.section__inner {
	.plate--element + .plate--element {
		margin-block-start: 2rem;
	}
}
