// Navigation Tabs.
.tabs-element-tabs {
  display: flex;
  align-items: stretch;

  .tab-link {
    flex: 1 0 auto;
    text-align: center;
    padding: 2rem;
    background-color: #CFE0E9;
    color: #4E7C96;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.8rem;
    width: 33%;

    &.active {
      background-color: #fff;
    }
  }
}

// Actuall content when clicked on navigation.
// @see form-tabs.js
.tabs-element-contents {
  padding: 4rem;
  background-color: white;
  margin-bottom: 4rem;

  .selector {
    border: 2px solid #EAEAEA;
  }

  .tab {
    display: none;

    &.active {
      display: block;
    }
  }
}

.question-type-text {
  margin: 2rem 0;
  text-align: center;
}

.tabs-element {
  margin-top: -4rem;
  @include media-breakpoint-up(lg) {
    margin-top: -140px;
    z-index: 1;
  }
}
