.accordion {
	&__item + &__item {
		margin-top: 2rem;
	}

	&__item {

		color: #333333;

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1rem 2rem;
			background: #ffffff;

			h3 {
				font-size: 2.4rem;
				line-height: 1.8;
			}

			// LOL. It works. Show proper SVG based on active class.
			.accordion-open {
				display: none;
			}
			.accordion-close {
				display: initial;
			}
			&:not(.accordion__item__header--active) {
				.accordion-open {
					display: initial;
				}
				.accordion-close {
					display: none;
				}
			}
		}

		&__body {
			background-color: #EFF2F4;
			@include transition;
			overflow: hidden;

			&:not(.accordion__item__body--active) {
				max-height: 0 !important;
			}

			&__inner {
				padding: 1rem 2rem;
			}
		}
	}

	svg {
		height: 2rem;
		width: 2rem;
		fill: #4E7C96;
	}
}