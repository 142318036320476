.map {
	width: 100%;
	padding-bottom: 40%;
}

.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
	height: 3rem;

	path {
		fill: map-get($colors, primary);
	}
}

[src="https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png"] {
	display: none !important;
}