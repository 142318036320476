.video-blocks {
  @include media-breakpoint-up($bpUp) {
    display: flex;
  }

  &.multiple {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: -1; // below video.
      top: 0;
      left: 10%;
      right: 10%;
      width: 75%;
      height: 90%;

      @include stripes(-28deg, #FFF8B6, #FFF246);

      @include media-breakpoint-up(md) {
        top: -10%;
      }
    }

    .video-wrapper {
      @include media-breakpoint-up(md) {
        width: 100%;
      }
      margin: 2.5rem;

      &:after {
        display: none;
      }

    }
  }

}