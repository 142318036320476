.header-slide {
  position: relative;
  .header-content-wrapper {
    @include media-breakpoint-up($bpUp) {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      width: 50%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

  }

  .header-content {
    padding: 1.8rem;
    @include media-breakpoint-up($bpUp) {
      text-align: center;
      color: white;
      max-width: 58ch;
    }
  }

  .btn__wrapper {
    @include media-breakpoint-up($bpUp) {
      justify-content: center;
    }
  }

  // Special button inside header-content slide.
  // Overrides the background-color and color.
  // Adds a white underline.
  .btn__header_slide {
    position: relative;
    padding: .8rem 1rem 1.2rem;
    background-color: transparent;
    font-weight: 600;
    color: #4E7C96;

    transition: height 0.2s ease,
    background-color 0.2s ease,
    color 0.1s ease;

    // Mobile before.
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 2px;
      right: .5rem;
      left: .5rem;
      background-color: #4E7C96;
      z-index: -1;
      transition: height 0.2s ease,
      background-color 0.2s ease,
      color 0.1s ease;

      @include media-breakpoint-up($bpUp) {
        background-color: white;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;
      color: white;

      &:before {
        height: 100%;
      }
      @include media-breakpoint-up($bpUp) {
        color: #4E7C96;
        &:before {
          background-color: white;
        }
      }
    }

    // Breakpoint before
    @include media-breakpoint-up($bpUp) {
      font-size: 2.4rem;
      text-align: center;
      color: white;
    }


  }

  h1 {
    font-weight: 700;
    font-size: 2.8rem;
    margin-bottom: 2rem;
    line-height: 1.4;
    color: #4E7C96;

    @include media-breakpoint-up($bpUp) {
      font-size: 5.2rem;
      color: white;
    }
  }

}