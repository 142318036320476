* {
	box-sizing: border-box;
	margin: 0;
}

html {
	font-size: 62.5%;
	overflow-x: hidden;
}

picture {
	&,
	img {
		display: block;
	}

	source {
		display: block;
		width: 100%;
		height: 100%;
	}
}

body {
	font-size: 1.8rem;
}