.selector {
   $self: &;
   cursor: pointer;
   position: relative;
   z-index: 5;
   [data-disabled="true"] {
      pointer-events: none;
      opacity: 0.5;
   }
   &__wrapper {
      select {
         display: none;
      }
   }

   &__input {
      padding: 1rem;
      background: lightgrey;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      svg {
         margin-left: 2rem;
         height: 1rem;
         pointer-events: none;
         @include transition;
      }
   }

   &__value {
      display: none;
   }

   &__list {
      position: absolute;
      bottom: 0;
      width: 100%;
      transform: translateY(100%);
      max-height: 0;
      @include transition;
      background: rgba(240, 240, 240, 1);
      overflow: hidden;

      &__item {
         padding: 1rem;
      }
   }
   &:not(.selector--open) {
      .selector__list {
         max-height: 0 !important;
      }
   }

   &--open {
      z-index: 11;

      svg {
         transform: rotate(180deg);
      }
   }

   &--closing {
      z-index: 11;
   }

   &--selected {
      #{$self}__value {
         display: block;
      }

      #{$self}__placeholder {
         display: none;
      }
   }
}

.file {
   &__drop {
      position: relative;
   }

   &__overlay {
      pointer-events: none;
      @include cover;
   }

   input {
      opacity: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
   }

   .drag_over {
      border-style: dashed;
   }

   .has_file {
      background: green;
   }

   &__remove {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
   }
}
