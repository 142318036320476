@font-face {
	font-family: "Social";
	font-weight: normal;
	font-style: normal;
	font-display: block;
	src: url("/theme/assets/fonts/social_media.woff2") format("woff2"),
		url("/theme/assets/fonts/social_media.woff") format("woff");
}

.icon-social {
	font-style: normal;
	font-family: "Social";

	&.icon-facebook:before {
		content: "\f09a";
	}

	&.icon-twitter:before {
		content: "\f099";
	}

	&.icon-envelope:before {
		content: "\f0e0";
	}

	&.icon-linkedin:before {
		content: "\f0e1";
	}

	&.icon-youtube-play:before {
		content: "\f16a";
	}

	&.icon-instagram:before {
		content: "\f16d";
	}

	&.icon-google:before {
		content: "\f1a0";
	}

	&.icon-behance:before {
		content: "\f1b4";
	}

	&.icon-pinterest-p:before {
		content: "\f231";
	}

	&.icon-whatsapp:before {
		content: "\f232";
	}

	&.icon-vimeo:before {
		content: "\f27d";
	}
}
