.p {
	&-t {
		&--none {
			padding-top: 0 !important;
		}

		&--small {
			@include sectionSpace(top, 3.3rem);
		}

		&--medium {
			@include sectionSpace(top, 4rem);
		}
		
		&--large {
			@include sectionSpace(top, 5rem);
		}
	}
	
	&-b {
		&--none {
			padding-bottom: 0 !important;
		}

		&--small {
			@include sectionSpace(bottom, 3.3rem);
		}

		&--medium {
			@include sectionSpace(bottom, 4rem);
		}
		
		&--large {
			@include sectionSpace(bottom, 5rem);
		}
	}
}

.m {
	&-t {
		&--none {
			margin-top: 0 !important;
		}
		&--small {
			@include sectionSpace(top, 3.3rem, margin);
		}

		&--medium {
			@include sectionSpace(top, 4rem, margin);
		}
		
		&--large {
			@include sectionSpace(top, 5rem, margin);
		}
	}
	
	&-b {
		&--none {
			margin-bottom: 0 !important;
		}

		&--small {
			@include sectionSpace(bottom, 3.3rem, margin);
		}

		&--medium {
			@include sectionSpace(bottom, 4rem, margin);
		}
		
		&--large {
			@include sectionSpace(bottom, 5rem, margin);
		}
	}
}