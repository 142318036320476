.contact_form {
	$self: &;
	background: white;

	&.has-question {
		display: none;
	}

	&.has-question.active {
		display: block;
	}

	button {
		border: none;
	}

	&__column,
	&__row {
		display: flex;
	}

	&__column {
		flex-wrap: wrap;
	}

	&__row {
		margin-right: -1 * map-get($padding, "column-mobile");
		margin-left: -1 * map-get($padding, "column-mobile");
		flex-direction: column;

		@include media-breakpoint-up($bpUp) {
			flex-direction: row;
			margin-right: -1 * map-get($padding, "column-desktop");
			margin-left: -1 * map-get($padding, "column-desktop");
		}
	}

	&__column {
		flex: 1 1 10%;

		#{$self}__field {
			width: 100%;
		}
	}

	&__label {
		font-weight: 500;
		font-size: 1.8rem;
		line-height: 4rem;
		color: #402B2B;
		margin-bottom: 1rem;
	}

	input[type='text'],
	input[type='tel'],
	input[type='email'],
	textarea {
		line-height: 1.4;
		padding: 1.8rem;
	}
	.file__drop,
	.selector,
	input,
	textarea {
		background: #FFFFFF;
		border: 2px solid #EAEAEA;
	}
	&__field {
		display: flex;
		flex-direction: column;
		//padding-right: map-get($padding, "column-mobile");
		//padding-left: map-get($padding, "column-mobile");
		//
		//@include media-breakpoint-up($bpUp) {
		//	padding-right: map-get($padding, "column-desktop");
		//	padding-left: map-get($padding, "column-desktop");
		//}

		&:not(:last-child) {
			margin-bottom: 4rem;
		}

		&.radio,
		&.checkbox {
			label {
				cursor: pointer;
				position: relative;
				//padding-left: 2.2rem;
				display: block;

				//&::before {
				//	@include pseudo-absolute(0.1rem, 0);
				//	width: 1.2rem;
				//	height: 1.2rem;
				//	border-radius: 0.1rem;
				//	border: 1px solid map-get($colors, "primary");
				//}
			}

			input {
				opacity: 0;
				visibility: hidden;
				position: absolute;

				&:checked + label {
					&::before {
						background-color: map-get($colors, "primary");
					}
				}
			}
		}

		&.radio {
			label {
				&::before {
					border-radius: 50%;
				}
			}
		}
		&--error {
			input,
			.selector,
			.radio-check__input,
			.file__drop {
				border-color: red;
			}
		}
	}

	.hide {
		display: none;
	}
}

.notification {
	position: relative;

	.message {
		opacity: 0;
		@include transition;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.success {
		.message__success {
			opacity: 1;
		}
	}

	&.error {
		.message__error {
			opacity: 1;
		}
	}
}

.grecaptcha-badge {
	display: none;
}

[hidden] {
	display: block !important;
}
.selector {
	$self: &;
	cursor: pointer;
	position: relative;
	z-index: 5;
	color: #333333;

	&__wrapper {
		select {
			display: none;
		}
	}

	&__input {
		padding: 1.8rem;
		background: white;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		svg {
			margin-left: 2rem;
			height: 1rem;
			pointer-events: none;
			@include transition;
		}
	}

	&__value {
		display: none;
	}
	&__placeholder,
	&__value {
		pointer-events: none;
	}

	&__list {
		position: absolute;
		bottom: 0;
		width: 100%;
		transform: translateY(100%);
		max-height: 0;
		@include transition;
		overflow: hidden;
		border: 1px solid #EAEAEA;
		color: currentColor;

		max-height: 400px !important;
		overflow-y: scroll;

		&__item {
			padding: 1rem;
			background: white;
		}
	}

	&--open {
		z-index: 11;

		#{$self}__list {
			max-height: 200px;
		}

		svg {
			transform: rotate(180deg);
		}
	}

	&--closing {
		z-index: 11;
	}

	&--selected {
		#{$self}__value {
			display: block;
		}

		#{$self}__placeholder {
			display: none;
		}
	}
}

.file {
	&__drop {
		position: relative;
		padding: 1.8rem;
	}

	&__overlay {
		pointer-events: none;
		color: #333333;
		padding: 1.8rem;
		@include cover;
	}

	input {
		opacity: 0;
		cursor: pointer;
		width: 100%;
		height: 100%;
	}

	.drag_over {
		border-style: dashed;
	}

	.has_file {
		background: green;
	}

	&__remove {
		cursor: pointer;
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);
	}
}

$radioCheckSize: 2rem;
.radio-check {
	$radioCheck: &;
	display: flex;
	align-items: center;
	&--radio {
		#{$radioCheck} {
			&__input {
				border-radius: 50%;
				&::before {
					border-radius: 50%;
				}
			}
		}
	}
	&__input {
		//margin-left: -$radioCheckSize;
		margin-left: 0;
		margin-right: 1rem;
	}

	&__input,
	input {
		position: relative;
		@include box($radioCheckSize);
		border: 1px solid map-get($colors, black);
		&::before {
			@include transition;
			width: 80%;
			height: 80%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			content: "";
			background-color: transparent;
		}
	}
	input {
		opacity: 0;
		z-index: 10;
		position: relative;

		&:checked {
			& + .radio-check__input {
				&::before {
					background-color: map-get($colors, primary);
				}
			}
		}
	}
}
