body,
button,
input,
select,
textarea {
	-webkit-font-smoothing: antialiased;
	font-family: map-get($fonts, primary);
}

ul {
	list-style: none;

	&,
	li {
		padding: 0;
		line-height: 1.666;
	}
}

p {
	line-height: 1.666;
}

h1,
h2,
h3,
h4 {
	font-family: inherit;
}

// Design specific weights.
h2 {
	font-weight: 500;

	font-size: 2.4rem;
	line-height: 3rem;
	letter-spacing: 0.05rem;

	@include media-breakpoint-up($bpUp) {
		font-size: 4.4rem;
		line-height: 6rem;
	}
}

h3 {
	font-weight: 500;
	font-size: 2rem;
	line-height: 2.5rem;
	letter-spacing: 0.01rem;


	@include media-breakpoint-up($bpUp) {
		font-size: 3.2rem;
		line-height: 5rem;
	}
}

h4 {
	font-weight: 500;
	font-size: 1.8rem;
	line-height: 2.8rem;
	letter-spacing: 0.05rem;

	@include media-breakpoint-up($bpUp) {
		font-size: 2.4rem;
		line-height: 3.8rem;
	}
}
