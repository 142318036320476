.form_steps {
	display: flex;

	&__navigation {
		flex: 0 auto;
		max-width: 40rem;
		padding: 3rem;
		background-color: rgb(239, 239, 239);

		button {
			display: block;

			&:not(:last-child) {
				margin-bottom: 1.5rem;
			}

			&.active {
				background-color: red;
			}
		}
	}

	&__wrapper {
		flex: 1;
		padding: 1rem;
		background-color: rgb(239, 239, 239);

		@include media-breakpoint-up(md) {
			padding: 3rem;
			margin-left: map-get($padding, "column-desktop");
		}
	}

	&__item {
		margin-right: -1 * map-get($padding, "column-mobile");
		margin-left: -1 * map-get($padding, "column-mobile");

		@include media-breakpoint-up($bpUp) {
			margin-right: -1 * map-get($padding, "column-desktop");
			margin-left: -1 * map-get($padding, "column-desktop");
		}

		&:not(.active) {
			display: none;
		}

		&__nav {
			padding-right: map-get($padding, "column-mobile");
			padding-left: map-get($padding, "column-mobile");

			@include media-breakpoint-up($bpUp) {
				padding-right: map-get($padding, "column-desktop");
				padding-left: map-get($padding, "column-desktop");
			}
		}
	}
}
