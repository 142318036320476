/*  Colors */
$colors: (
	primary-backup: rgb(0, 0, 0),
	primary: rgba(var(--color_primary_r), var(--color_primary_g), var(--color_primary_b), 1),
	secondary-backup: rgb(71, 71, 71),
	secondary: rgba(var(--color_secondary_r), var(--color_secondary_g), var(--color_secondary_b), 1),
	tertiary-backup: rgb(161, 161, 161),
	tertiary: rgba(var(--color_tertiary_r), var(--color_tertiary_g), var(--color_tertiary_b), 1),
	lightgrey: lightgrey,
	white: white,
	black: black,
	blue: #CFE0E9,
);

/* Fonts */
$fonts: (
	primary: var(--font_primary),
	secondary: var(--font_secondary),
);

$font-weights: (
	thin: 100,
	extra-light: 200,
	light: 300,
	regular: 400,
	medium: 500,
	semi-bold: 600,
	bold: 700,
	extra-bold: 800,
	black: 900,
);

$font-base: 1.6rem;

// Transition
$transition: (
	ease: 'ease-in-out',
	speed: .2s
);

/*  Padding */
$padding: (
	small: 1rem,
	medium: 3rem,
	large: 5rem,
	column-mobile: 15px,
	column-desktop: 15px
);

$paddingMobile: 5rem;
$paddingDesktop: 12rem;
$smallFactor: 0.5;
$largeFactor: 2;

/* Breakpoints */
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	xxxl: 1600px,
	xxxxl: 1800px,
	hd: 1920px,
);

$bpDown: sm;
$bpUp: md;
$bpUpNav: xl;
$bpDownNav: lg;

/* Navigation */
$toggleWidth: 25px;
$toggleHeight: 25px;
$toggleLineHeight: 3px;
$toggleLineBorderRadius: 2px;
$toggleLineColor: #0C496E;
$toggleLineColorActive: #0C496E;

/* Swiper */
$swiper-theme-color: black;
$swiper-preloader-color: black;
$swiper-navigation-color: white;
$swiper-pagination-color: white;
$swiper-navigation-size: 44px;