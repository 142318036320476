$nav-width: 70px;
.search-nav {
	position: relative;
	flex: 1 1 auto;
	height: 100%;
	max-width: 60px;
	align-items: center;
	justify-content: center;
	display: flex;

	// Reset button styling.
	button {
		background: none;
		border: none;
	}

	.search-button {
		&:hover {
			svg {
				fill: #4E7C96;
			}
		}
	}
	svg {
		fill: #0C496E;
		width: 2rem;
		height: 2rem;
	}
}

.search-nav-input {
	display: flex;
	height: 100%;
	justify-content: flex-end;
	align-items: center;
	opacity: 0;
	z-index: 1;

	background-color: gold;
	position: absolute;
	right: 0;
	left: 0;
	width: 0; // will animate width.
	transition: width .2s ease;

	.search-inner {
		display: flex;
		form {
			flex: 1;
			margin-right: 2rem;
		}
	}

	&.active {
		width: 100vw;
		background-color: white;
		opacity: 1;

		input {
			display: initial;
			border: none;
			border-bottom: .5px solid #C0E0F0;
		}
	}

	// Reset button styling.
	button {
		background: none;
		border: none;

		svg {
			width: 2rem;
			height: 2rem;
			fill: #4E7C96;
		}
	}
	input {
		line-height: 2;
		width: 100%;
		display: none;
	}

	[type='submit'] {
		display: none;
	}

}

.logo-payoff {
	position: absolute;
	bottom: 0;
	right: 0;

	max-width: 10rem;
	transform: translate(40px, 100%);

	@include media-breakpoint-up($bpUpNav) {
		max-width: 15rem;
		transform: translate(60px, 100%);
	}
}

.nav {
	position: relative;
	z-index: 99999;

	&__inner {
		display: flex;
		flex-direction: row;
		align-items: center;
		min-height: 60px;
		@include media-breakpoint-up($bpUpNav) {
			min-height: 110px;
		}
	}

	&__brand {
		margin-right: auto;

		a,
		img {
			height: inherit;
			width: 18rem;
			@include media-breakpoint-up($bpUpNav) {
				width: 28rem;
			}

			@include media-breakpoint-up(xxl) {
				width: 44rem;
			}
		}

		a {
			display: block;
			position: relative;
		}
	}

	&__content {
		@include media-breakpoint-down($bpDownNav) {
			background-color: map-get($colors, lightgrey);
			@include cover('bottom');
			transform: translateY(100%);
			@include transition(max-height);
			overflow: hidden;

			&:not(.nav__content--active) {
				max-height: 0 !important;
			}
		}

		@include media-breakpoint-up($bpUpNav) {
			//flex: 1 1 auto;
		}
	}

	&__toggler {
		order: 1;
		@include box($toggleWidth, $toggleHeight);
		position: relative;
		cursor: pointer;

		@include media-breakpoint-up($bpUpNav) {
			display: none;
		}

		&::after,
		&::before,
		span {
			@include pseudo-absolute;
			height: $toggleLineHeight;
			border-radius: $toggleLineBorderRadius;
			background-color: $toggleLineColor;
			width: 100%;
			@include transition;
		}

		&::before {
			top: 0;
		}

		span {
			top: 50%;
			transform: translateY(-75%);
		}

		&::after {
			top: 100%;
			transform: translateY(-100%);
		}

		&--active {

			&::after,
			&::before,
			span {
				background-color: $toggleLineColorActive;
			}

			&::before {
				transform: rotate(45deg);
				top: 10px;
			}

			&::after {
				transform: rotate(-45deg);
				top: 10px;
			}

			span {
				width: 0;
			}
		}
	}
}