[id='meesterbaan-wrapper'] {
  max-width: 500px;
  display: flex;
  margin: 0 auto;
}

//// Deze CSS is naar meesterbaan gestuurd voor gebruik in het iframe.
//@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500&display=swap');
//
//* {
//  font-family: 'Barlow', sans-serif;
//  font-weight: 500;
//  font-size: 18px;
//  line-height: 1.4;
//  letter-spacing: 0.5px;
//}
//
//.HeaderRow th {
//  font-weight: 500;
//  font-size: 44px;
//  text-align: left;
//  /* identical to box height, or 136% */
//  letter-spacing: 0.5px;
//  color: #4E7C96;
//}
//
//a.test {
//  color: #4E7C96;
//  font-weight: bold;
//  font-size: 18px;
//  line-height: 30px;
//  letter-spacing: 0.5px;
//  text-decoration: none;
//}
//a.test:hover {
//  text-decoration: underline;
//}
//.NormalRow p,
//.AlternatingRow p {
//  margin: 0;
//}
//.NormalRow > td,
//.AlternatingRow > td {
//  border-bottom: 1px solid;
//  padding-bottom: 1rem;
//  border-color: #C0E0F0;
//}
//.GridStyle {
//  border-spacing: 0 1rem;
//  border-collapse: unset !important;
//}
//
//p {
//  color: #333333;
//}
//
//// Linkjes naar vacatures.
//a {
//  font-weight: 700;
//  color: #4E7C96;
//}
//
//// Iframe titel element.
//h1 {
//  font-weight: 500;
//  font-size: 44px;
//  letter-spacing: 0.5px;
//  color: #4E7C96;
//}
//
