/* Preloader */
.swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	transform-origin: 50%;
	animation: swiper-preloader-spin 1s infinite linear;
	box-sizing: border-box;
	border: 4px solid $swiper-preloader-color;
	border-radius: 50%;
	border-top-color: transparent;
}

@keyframes swiper-preloader-spin {
	100% {
		transform: rotate(360deg);
	}
}