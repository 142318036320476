// Mobile first.
.intranet-nav-mobile {
	display: block;
	margin-top: 2rem;
	@include media-breakpoint-up($bpUpNav) {
		display: none;
	}
}
.intranet-nav {
	display: none;
	@include media-breakpoint-up($bpUpNav) {
		display: initial;
	}
}
.menu__wrapper {
	height: 100vh;
	background-color: white;
	padding: 2rem;

	@include media-breakpoint-up($bpUpNav) {
		flex-direction: row;
		height: auto;
		padding: initial;
	}
}
.menu {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-up($bpUpNav) {
		flex-direction: row;
		height: auto;
		padding: initial;
	}

	&__item {
		@include media-breakpoint-down($bpDownNav) {
			border-bottom: 0.5px solid #C0E0F0;
		}

		&.active {
			> a {

				&:after {
					content: '';
					position: absolute;
					height: 3px;
					bottom: 5px;
					background: currentColor;
					left: 0;
					right: 0;
				}

			}
		}
		&__link {
			display: inline-block;
			position: relative;
			margin-right: 3.5rem;
			padding: .5em 0;
			font-weight: 700;
			letter-spacing: 0.5px;
			border-bottom: 3px solid transparent;

			color: map-get($colors, primary);
			text-decoration: none;

			&:hover,
			&:focus {
				&:after {
					content: '';
					position: absolute;
					height: 3px;
					bottom: 5px;
					background: currentColor;
					left: 0;
					right: 0;
				}
			}

			&--active {
				&:after {
					content: '';
					position: absolute;
					height: 3px;
					bottom: 5px;
					background: currentColor;
					left: 0;
					right: 0;
				}

				@include media-breakpoint-down($bpDownNav) {
					svg {
						transform: rotate(180deg);
					}
				}
			}
		}
		svg {
			// break out of box because of design.
			// Hover on arrow breaks a little because of this...
			position: absolute;
			top: 2rem;
			height: 1rem;
			margin-left: .5em;
			@include transition;
			border-bottom: none; // Do not put underline on hover.
		}
		&--dropdown {

			&__wrapper {
				@include transition();
				overflow: hidden;
				width: 100%;
				@include media-breakpoint-down($bpDownNav) {
					&--active {
						border-top: 0.5px solid #C0E0F0;
					}
				}
			}
			&__item {
				background-color: #ffffff;
				@include media-breakpoint-down($bpDownNav) {
					& + & {
						border-top: 0.5px solid #C0E0F0;
					}
				}

				&__link {
					position: relative;
					display: inline-block;
					color: #0C496E;
					text-decoration: none;
					padding: .9rem 0;
					font-weight: 700;

					@include media-breakpoint-up($bpUpNav) {
						padding: 1.5rem;
					}

					&:hover,
					&:focus {
						&:after {
							content: '';
							position: absolute;
							height: 3px;
							bottom: 5px;
							background: currentColor;
							left: 0;
							right: 0;
							@include media-breakpoint-up($bpUpNav) {
								left: 15px;
								right: 15px;
							}
						}
					}
				}
			}
			@include media-breakpoint-down($bpDownNav) {
				&__wrapper {
					&:not(.menu__item--dropdown__wrapper--active) {
						height: 0 !important;
					}
				}
				&__item {
					padding-left: 1rem;
				}
			}
			@include media-breakpoint-up($bpUpNav) {
				position: relative;
				&__item {
					&--first {
						display: none;
					}
				}

				&__wrapper {
					position: absolute;
					transform: translateY(100%);
					bottom: 0;
					left: -1.8rem;
					@include transition;
				}

				ul {
					background-color: map-get($colors, lightgrey);
					display: flex;
					flex-direction: column;
				}

				&:not(:hover) {
					.menu__item--dropdown__wrapper {
						height: 0 !important;
					}
				}

				&:hover {
					svg {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
}
