
// If 1 video, use pattern background.
// If > 1..3 use yellow pattern.
.video-wrapper {

	position: relative;
	z-index: 0; // when column has background via color per column section section.
	margin: 4rem;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 15%;
		z-index: -1; // below video.
		top: -10%;
		@include stripes(-28deg, #BD1F2B, #DB7E69);
	}

	// Card color themes!
	// Geel
	&.candlelight {
		.video__title {
			background-color: #FFD912;
			color: #333333;
		}
		.card-content {
			background-color: #FFFCD7;
		}
	}
	// Licht geel
	&.turbo {
		.video__title {
			background-color: #FFEE04;
			color: #333333;
		}
		.card-content {
			background-color: #FFF8D4;
		}
	}

	// Donker geel
	&.scotch-mist {
		.video__title {
			background-color: #FFC804;
			color: #333333;
		}
		.card-content {
			background-color: #FFF790;
		}
	}

	// Donker blauw
	&.deep-sea-green {
		.video__title {
			background-color: #0C496E;
			color: #C0E0F0;
		}
		.card-content {
			background-color: #C0E0F0;
		}
	}


	// Blauw
	&.wedgewood {
		.video__title {
			background-color: #4E7C96;
			color: #E1E9ED;
		}
		.card-content {
			background-color: #E5F0F7;
		}
	}

	// roze
	&.tuft-bush {
		.video__title {
			background-color: #FFD8C9;
			color: #CF6047;
		}
	}


	// Rood
	&.cardinal {
		.video__title {
			background-color: #BD1F2B;
			color: #FFE7DE;
		}
		.card-content {
			background-color: #FFE7DE;
		}
	}

	// Licht rood
	&.raw-sienna {
		.video__title {
			background-color: #CF6047;
			color: #FFE7DE;
		}
		.card-content {
			background-color: #FFE7DE;
		}
	}
}
.video {
	padding-bottom: 56%;
	position: relative;
	width: 100%;

	&,
	video {
		background-color: #4E7C96;
	}

	&__overlay,
	iframe,
	video {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	&__overlay {
		@include transition;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		img,
		picture {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__play {
		z-index: 1;
		position: relative;
		background: none;
		border: none;
		cursor: pointer;
		@include transition(transform);

		&:hover {
			transform: scale(120%);
		}

		svg {
			height: 50px;

			path {
				fill: white;
			}
		}
	}

	&__title {
		background-color: beige;
		line-height: 1.2;
		padding: 1rem 2rem;
		display: inline-block;

		h2 {
			font-size: 1.8rem;
			margin: 0;
			line-height: 3rem;
		}
	}

	&--loaded {
		.video__overlay {
			opacity: 0;
			pointer-events: none;
		}

		.video__play {
			transform: scale(0%);
		}
	}
}