:active,
:focus {
	outline: none;
}

.btn {
	font-weight: 700;
	cursor: pointer;
	@include transition;
	padding: 10px 15px;
	font-size: 1.6rem;
	text-decoration: none;

	&__wrapper {
		display: flex;
		width: 100%;

		&--center {
			justify-content: center;
		}

		&--right {
			justify-content: flex-end;
		}
	}

	&,
	&__primary {
		background-color: #4E7C96;
		color: #CFE0E9;
	}

	&__secondary {
		background-color: #CFE0E9;
		color: #4E7C96;
	}

	&:hover {
		text-decoration: underline;
	}

	&:active,
	&:focus {

	}
}