.plate--container {
	// max-width: unset;
}

.plate--column {
	padding-right: map-get($padding, "column-mobile");
	padding-left: map-get($padding, "column-mobile");
}

.plate--container,
.plate--container-fluid {
	padding-right: map-get($padding, "column-mobile");
	padding-left: map-get($padding, "column-mobile");
}

.plate--row {
	margin-right: -1 * map-get($padding, "column-mobile");
	margin-left: -1 * map-get($padding, "column-mobile");
}
@include media-breakpoint-up(md) {
	.plate--column {
		padding-right: map-get($padding, "column-desktop");
		padding-left: map-get($padding, "column-desktop");
	}

	.plate--container,
	.plate--container-fluid {
		padding-right: map-get($padding, "column-desktop");
		padding-left: map-get($padding, "column-desktop");
	}

	.plate--row {
		margin-right: -1 * map-get($padding, "column-desktop");
		margin-left: -1 * map-get($padding, "column-desktop");
	}
}

picture {
	position: relative;

	source {
		position: absolute;
		top: 0;
	}
}
