.quote-slider .swiper-wrapper {
	align-items: center;


}

.slider__pagination {
	text-align: center;
	padding: 0 1rem 4rem;

	&.swiper-pagination-bullets {
		.swiper-pagination-bullet {
			margin: 0 .8rem;
			width: 1.4rem;
			height: 1.4rem;
			background-color: #CFE0E9;
			opacity: 1;
		}

		.swiper-pagination-bullet-active {
			background-color: #4E7C96;
		}
	}

}

.quote-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	picture {
		display: inline-block;
		max-width: 150px;

		img {
			border-radius: 50%;
		}
	}
}

.quote-slider {
	overflow: hidden;
	figure {
		position: relative;
		background-color: white;
		max-width: 840px;
		padding: 1rem;
		font-size: 80%;

		@include media-breakpoint-up($bpUp) {
			padding: 4rem;
			font-size: 100%;
		}

		// stripe pattern on left and right side of quote slide.
		$quote-offset: 3rem;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			height: 60%;
			width: $quote-offset;
			z-index: -1;
		}

		&:before {
			left: -$quote-offset;
			top: 5rem;
			@include stripes(-28deg, #BD1F2B, #DB7E69);
		}

		&:after {
			right: -$quote-offset;
			bottom: 5rem;
			@include stripes(-28deg, #4E7C96, #7EABC7);
		}
	}
}


blockquote {
	font-weight: 500;
	font-size: 1.6em;
	line-height: 1.6;
	letter-spacing: .05em;
	color: #333333;
	margin-bottom: 1.5em;
}

.subtitle {
	font-weight: 500;
	font-size: 1em;
	line-height: 1;
	letter-spacing: 0.01em;
	color: #B7B6B6;
}

figcaption {
	font-weight: 500;
	font-size: 1.2em;
	line-height: 2;
	letter-spacing: .05em;
	color: #4E7C96;
	margin-bottom: 2rem;
}


// Navigation next and previous.
.swiper-button-next,
.swiper-button-prev {
	color: inherit;
	position: absolute;
	//top: 50%;
	top: -4em; // Easier for now since column changes continuesly...
	transform: translateY(-50%);
	z-index: 1;
	cursor: pointer;

	svg {
		fill: currentColor;
		height: 4rem;
		width: 4rem;
	}

	&.swiper-button-disabled {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}
}

// Positioning the arrows.
.swiper-button-prev {
	left: 1rem;
}

.swiper-button-next {
	right: 1rem;
}