.socials {
  .socials-item {
    display: flex;
    align-items: center;

    span {
      width: 2em;
      display: flex;
      justify-content: center;
    }
  }
  a {
    font-weight: 700;
    text-decoration: none;

    &:hover,
    &:focus  {
      text-decoration: underline;
    }
  }
}
