.index-title {
  font-weight: 500;
  font-size: 4.4rem;
  line-height: 6rem;
  letter-spacing: .05rem;
  color: #4E7C96;
  margin: 6rem 0;
}

.section-title {
  font-weight: 500;
  font-size: 4.4rem;
  line-height: 6rem;
  letter-spacing: .05rem;
  color: #4E7C96;
  margin: 6rem 0 0;
}

.article {
  &.index {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    font-size: 1.8rem;
    text-decoration: none;
    color: #333333;
    margin: 3.3rem 0;

    @include media-breakpoint-up($bpUp) {
      margin: 6rem 0;
      flex-direction: column;
    }

    // Picture
    picture {
      max-width: calc(60% - 100px);
      @include media-breakpoint-up($bpUp) {
        width: auto;
        max-width: initial;
      }
    }

    // Content wrapper to achieve a bit of an alignment of buttons and title, date and introduction.
    .index-content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      .teaser,
      .btn__wrapper {
        display: none;
        @include media-breakpoint-up($bpUp) {
          display: initial;
        }
      }

      @include media-breakpoint-up($bpUp) {
        flex: 1 0 auto;
        margin-left: 0;
      }

      .teaser {
        margin-top: auto;
      }
    }
    .meta {
      margin-top: 0;
      margin-bottom: .5rem;

      span:first-of-type {
        line-height: 1;
      }

      @include media-breakpoint-up($bpUp) {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        span:first-of-type {
          line-height: 2;
        }
      }
    }

    h2 {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 3rem;
    }

    h2,
    p {
      margin-bottom: 1rem;

      @include media-breakpoint-up($bpUp) {
        margin-bottom: 3.3rem;
      }

    }
  }
}

// meta like author, location and timestamp.
.meta {
  margin-top: 2rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  letter-spacing: .1rem;

  .meta-item {

    display: flex;
    align-items: center;

    svg {
      width: 2rem;
      max-height: 2rem;
      margin-right: 1rem;
      fill: #FFC804;
    }
  }

  span {

    font-weight: 500;

    /* or 144% */
    display: flex;
    flex-direction: column;
    letter-spacing: 1px;
    line-height: 2;
    color: #B7B6B6;
  }
}

.news-empty-column {
  @include media-breakpoint-down(lg) {
    .plate--column {
      min-height: 0;
    }
  }
}
.news-image-row {
  @include media-breakpoint-up(xl) {
    margin-bottom: 5rem;
  }
}

.news #section-body {
  padding-top: 2rem;
}

h1 {
  margin-top: 0;
  font-size: 4.4rem;
  line-height: 6rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: #4E7C96;
}

.introduction {
  font-size: 2.4rem;
  line-height: 3.8rem;
  font-weight: 500;
  letter-spacing: .05rem;
  color: #4E7C96;
}

.post_nav {


  & + & {
    margin-top: 2rem;
  }

  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  a {
    display: inline-block;
    margin: 0 1rem;

    // hide buttons when last or first of array.
    &.end {
      display: none;
    }
  }
}

