footer {
  background-color: map-get($colors, blue);

  padding: 3rem 0;

  @include media-breakpoint-up($bpUp) {
    padding: 6rem 0;
  }

  // Specific overrides because only applies in footer.
  h2, h4, p, a, i {
    color: #4E7C96;
  }
  h2 {
    margin-bottom: 1.6rem;
  }
  h4 {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  p {
    font-weight: 500;
  }

  .socials,
  .paragraph {
    a {
      font-weight: 700;
      text-decoration: none;
      line-height: 1.8;

      &:hover,
      &:focus  {
        text-decoration: underline;
      }
    }
  }

  // Override btn__secondary class.
  .btn__secondary {
    background-color: #4E7C96;
    color: #CFE0E9;
  }

  // Bigger padding-bottom for map only on footer.
  .map {
    padding-bottom: 60%;
    margin-top: -20px;
  }

}

.footer-stripes {
  height: 100px;
  // L1: #7EABC7
  // L2: #CFE0E9
  @include stripes(-28deg, #4E7C96, #7EABC7);
}




